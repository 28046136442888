/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { navigate } from '@reach/router';
import { RiArrowDropRightLine } from 'react-icons/ri';
import CardWidget from '../../../shared/Card';

const FeaturedPosts = ({ title, posts }) => {
	const maxCards = 3;
	let count = 0;

	const postData = posts.filter((post) => {
		let included = false;
		if (post.featured && count < maxCards) {
			included = true;
			count++;
		}
		return included;
	});
	return (
		<>
			<Row className="d-flex justify-content-center mt-0 mb-4">
				<h3 className="blog-featured">{title}</h3>
			</Row>
			<Row>
				{postData &&
					postData.length > 0 &&
					postData.map((item) => {
						const buttons = [
							{
								href: '',
								text: (
									<>
										Read the full article
										<span className="float-right mr-4">
											<RiArrowDropRightLine size={18} />
										</span>
									</>
								),
								className:
									'text-xs w-100 float-left text-left pl-0 pb-0 mb-0 btn-link text-uppercase btn-blog',
								color: 'link',
								onClick: () => navigate(item.pathname),
							},
						];
						return (
							<Col xs="12" md="4" key={item.id} className="mb-4 mb-md-0">
								<CardWidget
									key={`${item.id}`}
									hasTitle
									title={item.title}
									hasSubtitle={false}
									description={ReactHtmlParser(item.excerpt)}
									hasImage
									hasAvatar={false}
									className="mb-2 mb-md-0 shadow-sm h-100 card-blog"
									classNameTitle="text-left blog-title"
									classNameSubTitle="text-s text-left"
									cardBodyContainer="d-flex flex-column justify-content-between p-card-blog-body"
									src={item.image}
									altImage={item.altImage}
									buttons={buttons}
								/>
							</Col>
						);
					})}
			</Row>
		</>
	);
};

FeaturedPosts.propTypes = {
	title: PropTypes.string,
};

FeaturedPosts.defaultProps = {
	title: 'Featured Posts',
};

export default FeaturedPosts;
