import React from 'react';
import { Container } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import {
	FaFacebookF,
	FaTwitter,
	FaGooglePlusG,
	FaLinkedinIn,
	FaPinterestP,
} from 'react-icons/fa';
import dayjs from 'dayjs';
import Layout from '../../../shared/Layout';
import SEO from '../../../seo';
import SectionContainer from '../../sections/SectionContainer';
import Title from '../../../shared/Title';
import FeaturedPosts from '../../sections/FeaturedPosts';

import defaultAvatar from '../../../../images/default-avatar.png';
import useReduxHandler from '../../../../hooks/useReduxHandler';
import DynamicImage from '../../../../demo/components/DynamicImage';

const BlogPostTemplate = ({ pageContext }) => {
	const {
		pathname: path,
		slug,
		title,
		description,
		metatitle,
		image,
		altImage,
		content,
		author,
		avatar,
		posts,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
	} = pageContext;
	// const authorTitle = author || 'Billshark';
	const { isLoggedIn } = useReduxHandler();
	const isLogged = isLoggedIn();

	const transform = (node, index) => {
		const item = { ...node };

		// if (
		// 	item.type === 'tag' &&
		// 	item.name === 'a' &&
		// 	item.attribs.href.includes('http')
		// ) {
		// 	item.attribs.target = '_blank';
		// 	return convertNodeToElement(item, index, transform);
		// }

		if (item.type === 'tag' && item.name === 'img') {
			return (
				<DynamicImage
					src={item.attribs.src}
					alt={item.attribs.alt || item.attribs.src}
					className="img-blog"
				/>
			);
		}
		return undefined;
	};

	const options = {
		decodeEntities: true,
		transform,
	};

	return (
		<>
			<Layout
				isPrivate={false}
				isLogged={isLogged}
				type="blogPost"
				header="dark"
				classNameMain="bg-blog"
				menu={headerMenus}
				footerTopMenu={footerTopMenus}
				footerBottomMenu={footerBottomMenus}
				path={path}
				slug={slug}
			>
				<SEO
					title={title}
					pathname={path}
					description={description}
					metatitle={metatitle}
				/>
				<SectionContainer className="blog-post-section" id="blog-post">
					<Container className="bg-white shadow-sm" style={{ padding: '0' }}>
						<div className="my-2">
							<DynamicImage
								src={
									image?.includes('http')
										? image
										: `${process.env.CDN_URL}${image}`
								}
								alt={altImage || title}
								className="img-blog"
							/>
						</div>
						<div
							style={{
								marginLeft: 'auto',
								marginRight: 'auto',
								paddingBottom: '100px',
								paddingLeft: '20px',
								paddingRight: '20px',
							}}
						>
							<Title
								title={title}
								headingTitle="h1"
								classNameTitle="text-center blog-title-post"
								style={{ marginTop: '50px', marginBottom: '40px' }}
							/>
							{ReactHtmlParser(content, options)}

							<div className="btn-group" style={{ marginTop: '60px' }}>
								{/* <ButtonWidget text="Facebook" color="primary" />
							<ButtonWidget text="Twitter" color="default" />
							<ButtonWidget text="Google+" color="danger" />
							<ButtonWidget text="Linkedin" color="primary" /> */}
								<a href="/" className="facebook-btn mr-3" aria-label="facebook">
									<FaFacebookF color="white" />
								</a>
								<a href="/" className="twitter-btn mr-3" aria-label="twitter">
									<FaTwitter color="white" />
								</a>
								<a href="/" className="google-btn mr-3" aria-label="google">
									<FaGooglePlusG color="white" />
								</a>
								<a href="/" className="linkedin-btn mr-3" aria-label="linkedin">
									<FaLinkedinIn color="white" />
								</a>
								<a href="/" className="pinteres-btn" aria-label="pinteres">
									<FaPinterestP color="white" />
								</a>
							</div>
							{author && (
								<div
									className="d-flex align-items-center"
									style={{ marginTop: '40px' }}
								>
									<img
										src={avatar ? avatar.url : defaultAvatar}
										className="rounded-circle"
										width={60}
										alt="avatar"
									/>
									<div className="ml-3">
										<p className="blog-author">For {author}</p>
										<p className="blog-post-year">{dayjs().format('YYYY')}</p>
									</div>
								</div>
							)}
						</div>
					</Container>
				</SectionContainer>
				<SectionContainer className="mb-5" id="feature-posts">
					<Container>
						<FeaturedPosts posts={posts} />
					</Container>
				</SectionContainer>
				);
			</Layout>
		</>
	);
};

export default BlogPostTemplate;
