/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardImg, CardBody, CardLink, Row, Col } from 'reactstrap';
import ButtonWidget from '../Button';
import Title from '../Title';

function CardWidget(props) {
	const {
		className,
		style,
		srcAvatar,
		hasImage,
		hasAvatar,
		src,
		altImage,
		description,
		widthImage,
		heightImage,
		hasTitle,
		cardImgContainer,
		cardBodyContainer,
		classNameTitle,
		classNameSubTitle,
		descriptionClassName,
		title,
		hasSubtitle,
		subtitle,
		buttons,
		links,
		vertical,
	} = props;
	return vertical ? (
		<Card>
			<CardBody className="text-left">
				<Row>
					<Col xs={4} md={4}>
						<div className={cardImgContainer}>
							{hasImage &&
								(typeof src === 'object' ? (
									src
								) : (
									<CardImg
										top
										src={src}
										alt={altImage || 'Card image cap'}
										style={{ maxWidth:'100%', height: 'auto' }}
									/>
								))}
						</div>
					</Col>
					<Col xs={8} md={8} className={cardBodyContainer}>
						{hasAvatar && (
							<div className="card-avatar-container">
								<img
									src={srcAvatar}
									alt="Card avatar"
									className="rounded-circle m-auto"
								/>
							</div>
						)}
						<Title
							headingTitle="h6"
							hasTitle={hasTitle}
							title={title}
							classNameTitle="font-weight-bold"
							headingSubTitle="h6"
							hasSubTitle={hasSubtitle}
							subtitle={subtitle}
						/>
						<div className={descriptionClassName}>{description}</div>
						{buttons &&
							buttons.length > 0 &&
							buttons.map((item) => {
								return (
									<ButtonWidget
										text={item.text}
										key={item.text}
										className={item.className}
										color={item.color}
										onClick={item.onClick}
									/>
								);
							})}
						{links &&
							links.length > 0 &&
							links.map((item) => {
								return (
									<CardLink
										key={item.href}
										href={item.href}
										className={item.className}
									>
										{item.linkText}
									</CardLink>
								);
							})}
					</Col>
				</Row>
			</CardBody>
		</Card>
	) : (
		<Card className={className} style={style}>
			<div
				className={cardImgContainer}
				style={{ minHeight: '192px', overflow: 'hidden' }}
			>
				{hasImage &&
					(typeof src === 'object' ? (
						src
					) : (
						<CardImg
							top
							src={src}
							alt={altImage || 'Card image cap'}
							style={{ maxWidth:'100%', height: 'auto' }}
						/>
					))}
			</div>
			{hasAvatar && (
				<div className="card-avatar-container align-content-center justify-content-center">
					<img
						src={srcAvatar}
						alt="Card avatar"
						className="rounded-circle m-auto w-100"
					/>
				</div>
			)}
			<CardBody className={`${cardBodyContainer} card-body-wrapper`}>
				<Title
					headingTitle="h6"
					hasTitle={hasTitle}
					title={title}
					classNameTitle={classNameTitle}
					classNameSubTitle={classNameSubTitle}
					headingSubTitle="h6"
					hasSubTitle={hasSubtitle}
					subtitle={subtitle}
				/>
				<div className={`${descriptionClassName} description-text`}>
					{description}
				</div>
				<div className="read-more-buttons">
					{buttons &&
						buttons.length > 0 &&
						buttons.map((item) => {
							return (
								<ButtonWidget
									text={item.text}
									key={item.text}
									className={item.className}
									color={item.color}
									onClick={item.onClick}
								/>
							);
						})}
					{links &&
						links.length > 0 &&
						links.map((item) => {
							return (
								<CardLink
									key={item.href}
									href={item.href}
									className={item.className}
								>
									{item.linkText}
								</CardLink>
							);
						})}
				</div>
			</CardBody>
		</Card>
	);
}

CardWidget.propTypes = {
	className: PropTypes.string,
	classNameTitle: PropTypes.string,
	classNameSubTitle: PropTypes.string,
	srcAvatar: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.objectOf(PropTypes.any),
	]),
	style: PropTypes.objectOf(PropTypes.any),
	hasImage: PropTypes.bool,
	hasAvatar: PropTypes.bool,
	src: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.objectOf(PropTypes.any),
	]),
	altImage: PropTypes.string,
	widthImage: PropTypes.string,
	cardBodyContainer: PropTypes.string,
	hasTitle: PropTypes.bool,
	cardImgContainer: PropTypes.string,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.any),
	]),
	description: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.any),
	]),
	descriptionClassName: PropTypes.string,
	hasSubtitle: PropTypes.bool,
	subtitle: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.any),
	]),
	buttons: PropTypes.arrayOf(PropTypes.any),
	links: PropTypes.arrayOf(PropTypes.any),
	vertical: PropTypes.bool,
};

CardWidget.defaultProps = {
	style: null,
	className: '',
	hasImage: false,
	hasAvatar: true,
	cardImgContainer: 'd-flex justify-content-center',
	cardBodyContainer: 'text-center',
	classNameTitle: 'font-weight-bold',
	classNameSubTitle: '',
	src: '',
	altImage: null,
	srcAvatar: '',
	widthImage: '12',
	hasTitle: true,
	title: 'Title',
	description: 'Description',
	descriptionClassName: 'text-xs text-left',
	hasSubtitle: false,
	subtitle: 'subtitle',
	buttons: null,
	links: null,
	vertical: false,
};

export default CardWidget;
