/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

function ButtonWidget(props) {
	const { text, className, color, onClick, customWidth } = props;
	return (
		<Button
			className={className}
			color={color}
			onClick={onClick}
			style={{ width: customWidth }}
		>
			{text}
		</Button>
	);
}

ButtonWidget.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.objectOf(PropTypes.any),
	]).isRequired,
	className: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
};

ButtonWidget.defaultProps = {
	className: 'mr-0 mr-md-4 ',
	color: 'secondary',
	onClick: () => {},
};

export default ButtonWidget;
